import HomePage from './Components/HomePage';
import Redirect from './Components/Redirect';
import RedirectParam from './Components/RedirectParam';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePageKore from './ComponentsKore/HomePageKore2.0';
import RedirectKore from './ComponentsKore/RedirectKore';
import RedirectKoreParams from './ComponentsKore/RedirectKoreParams';

function App() {
	return (
		<div>
			<Router>
				<Routes>
					<Route exact path="/" element={<HomePage />} />
					{/* <Route path="/Redirect" element={<Redirect />} /> */}
					<Route path="/Redirect/:data" element={<RedirectParam />} />
					<Route path="/Kore2.0" element={<HomePageKore />} />
					<Route path="/RedirectKore" element={<RedirectKore />} />

					<Route path="/RedirectKore/:data" element={<RedirectKoreParams />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
