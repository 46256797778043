import React from 'react';
import '../Style-SheetsKore/LernMoreButton.css';
import arrow_forward_ios from '../ImagesKore/arrow_forward_ios.png';
const LernMoreButton = (props) => {
	return (
		<>
			<button
				geid="1"
				href="https://www.amazon.com/Zulay-Milk-Frother-Handheld-Operated/dp/B074ZL35DH?th=1"
				className="designeB"
			>
				<img src={arrow_forward_ios} alt="arrow" />
				<span>Aprende más</span>
			</button>
		</>
	);
};

export default LernMoreButton;
