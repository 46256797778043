import React from 'react';
import Review from './Review';
import fiveStarsKore from '../ImagesKore/5starKore.png';
import fourStars from '../ImagesKore/fourStars.png';

// import '../Style-SheetsKore/Reviews.css';
const Reviews = () => {
	return (
		<div className="reviewsContainer">
			<h1>Clientes de Kore 2.0</h1>
			<Review
				src={fiveStarsKore}
				title={'Funciona tan bien como los modelos caros'}
				semiData={'Jem N. -05 de nov. de 2021'}
				data={
					'He mirado muchos relojes inteligentes y me sorprendió lo mucho más barato que es Kore 2.0. Tiene todas las mismas aplicaciones y funciona tan bien como los modelos caros. Ahorré casi $ 150 y me encanta mi Kore 2.0.'
				}
			/>
			<Review
				src={fourStars}
				title={'¡Amo esta cosa!'}
				semiData={'Jorge L. -10 de nov. de 2021'}
				data={
					'Recomiendo encarecidamente esto a cualquier persona que haga ejercicio. Es casi como tener un entrenador personal. Puede consultar sus estadísticas y programar actualizaciones de salud. Es genial ver todos los datos.                '
				}
			/>
			<Review
				src={fourStars}
				title={'¡No pague un brazo y una pierna!'}
				semiData={'Lindsey V. - 18 de nov. de 2021'}
				data={
					'¡Amo mi Kore 2.0! Es cómodo, fácil de usar y todas las aplicaciones de fitness funcionan muy bien. ¿Por qué gastar tanto cuando esto cuesta una décima parte del precio y tiene todas las mismas características?                '
				}
			/>
			<Review
				src={fiveStarsKore}
				title={'¡5 estrellas!'}
				semiData={'Jaimie M. - 20 de nov. de 2021'}
				data={
					'¡Uso mi Kore 2.0 todos los días! Es genial poder ver cómo va mi entrenamiento o qué tan bien estoy durmiendo. Los controles son fáciles de aprender, se ajustan muy cómodos y son precisos. ¡5 estrellas!                '
				}
			/>
		</div>
	);
};

export default Reviews;
