import React, { useState } from 'react';
import '../Style-SheetsKore/QuestionItem.css';
import PlusButton from '../ImagesKore/PlusButton.png';
import Image from '../Components/Image';
const QuestionItem = (props) => {
	const [revealAnswer, setRevealAnswer] = useState(false);
	return (
		<div>
			<div className="divQuestion">
				<br />

				<button
					className={'buttonQuestion'}
					onClick={() => {
						setRevealAnswer(!revealAnswer);
					}}
				>
					<span className="QuestionContainer">
						<span> {props.question}</span>
						<Image
							src={PlusButton}
							alt={'PlusButton'}
							className={
								revealAnswer
									? 'PlusImgQuestion rotateToX'
									: 'PlusImgQuestion rotateToPlus'
							}
						/>
					</span>
				</button>
			</div>

			{revealAnswer ? (
				<div className={'answerData'}>
					<br />
					{props.answer}
				</div>
			) : (
				<span></span>
			)}
		</div>
	);
};

export default QuestionItem;
