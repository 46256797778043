import React from 'react';
import '../Style-SheetsKore/IconBox.css';
import Image from '../Components/Image';

const IconBox = (props) => {
	return (
		<div
			className={props.class}
			// style={props.borderRight ? { borderRight: '1px solid' } : {}}
		>
			<Image src={props.logo} alt={props.logo.toString()} />
			<p>{props.text}</p>
		</div>
	);
};

export default IconBox;
