import React from 'react';
import IconBox from './IconBox';
import '../Style-SheetsKore/IconsBoxes.css';
//----------------------------------------------------------------
import statistic from '../ImagesKore/IconsBoxsIcons/statistics.png';
import water from '../ImagesKore/IconsBoxsIcons/water.png';
import touch from '../ImagesKore/IconsBoxsIcons/touch.png';
import battery from '../ImagesKore/IconsBoxsIcons/battery.png';

const IconsBoxes = () => {
	return (
		<div className="divContainer">
			<IconBox
				logo={statistic}
				text={'Estadísticas de ejercicio en tiempo real'}
				class={'containerDiv'}
			/>
			<IconBox
				logo={water}
				text={'Resistente al sudor y a las salpicaduras'}
				class={'containerDiv'}
			/>
			<IconBox
				logo={touch}
				text={'Navegación por pantalla táctil'}
				class={'containerDiv'}
			/>

			<IconBox
				logo={battery}
				text={'Hasta una semana de duración de la batería'}
				class={'containerDivLast'}
			/>
		</div>
	);
};

export default IconsBoxes;
