import React from 'react';
import ActivityBox from './ActivityBox';
// import '../Style-SheetsKore/ActivitiesBoxes.css';
import girlLockAtHerWrist from '../ImagesKore/ActivitiesBoxsIcons/girlLockAtHerWrist.png';
import fitTracking from '../ImagesKore/ActivitiesBoxsIcons/fit-tracking.png';
import hoodieMan from '../ImagesKore/ActivitiesBoxsIcons/hoodieMan.png';
import SleepingWoman from '../ImagesKore/ActivitiesBoxsIcons/SleepingWoman.png';
import ManHoldingLeg from '../ImagesKore/ActivitiesBoxsIcons/ManHoldingLeg.png';
import appOnPhone from '../ImagesKore/ActivitiesBoxsIcons/appOnPhone.png';

const ActivitiesBoxes = () => {
	return (
		<div>
			<h1>Razones por las que te encantará Kore 2.0</h1>
			<span className="activityBoxesDivContainer">
				<ActivityBox
					logo={girlLockAtHerWrist}
					title={'Seguimiento de salud'}
					text={
						'Realice un seguimiento de métricas de salud importantes como la frecuencia cardíaca, la temperatura corporal y los niveles de oxígeno en sangre en segundos.'
					}
				/>
				<ActivityBox
					logo={fitTracking}
					title={'Seguimiento de actividad física'}
					text={
						'Siga superando sus objetivos de fitness más rápido con los modos multideportivos integrados y el seguimiento de los pasos y las calorías quemadas las 24 horas del día, los 7 días de la semana.'
					}
				/>
				<ActivityBox
					logo={hoodieMan}
					title={'Estarás más comprometido'}
					text={
						'Programe lecturas diarias de signos vitales o alertas de inactividad para recordarle que debe levantarse y moverse para que pueda vivir una vida más saludable'
					}
				/>
			</span>

			<span className="activityBoxesDivContainer">
				<ActivityBox
					logo={SleepingWoman}
					title={'Mejora tu sueño'}
					text={
						'Analiza tus patrones de sueño para descansar mejor por la noche. Siéntete más alerta y renovado al día siguiente para que puedas rendir al máximo.'
					}
				/>
				<ActivityBox
					logo={ManHoldingLeg}
					title={'Alertas de un vistazo'}
					text={
						'Las alertas de vibración de llamadas, mensajes de texto y calendario le permiten concentrarse en la tarea en cuestión sin distracciones de su teléfono.'
					}
				/>
				<ActivityBox
					logo={appOnPhone}
					title={'Está hecho para ti'}
					text={
						'Interfaz personalizable, batería de larga duración y compatibilidad con Apple y Android para que puedas usarla todos los días.'
					}
				/>
			</span>
		</div>
	);
};

export default ActivitiesBoxes;
