import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const RedirectParam = () => {
	let params = useParams();
	console.log(params);

	useEffect(() => {
		console.log(params);
		window.location.replace('https://nerve.eshkol.io/go?' + params.data);
	}, []);
	return <div></div>;
};

export default RedirectParam;
