import React from 'react';
import Logo from '../images/56ca43f718144089be9414411bbf51d1.png';
const HeadLogo = () => {
	return (
		<div>
			<img src={Logo} alt={'logo'} />
		</div>
	);
};

export default HeadLogo;
