import React from 'react';
import '../Style-SheetsKore/GuaranteeSection.css';
import Image from '../Components/Image';
import money_back from '../ImagesKore/money_back-es.png';
const GuaranteeSection = () => {
	return (
		<div className="GuaranteeSectionContainer">
			<div className="GuaranteeSectionBorderContainer">
				<span className="GuaranteeSectionFirst">
					<Image src={money_back} alt={'money_back'} className="GuaranteeImg" />
					<p className="GuaranteeSectionBolderP">
						Estamos seguros de que te va a encantar Su rastreador corporal
						inteligente Kore 2.0
					</p>
				</span>
				<span className="GuaranteeSectionSecond">
					<p>
						Pero en caso de que no esté satisfecho, le ofrecemos nuestra
						garantía de devolución de dinero de -30 días. Devuelva su paquete
						para obtener un reembolso completo.
					</p>
				</span>
			</div>
		</div>
	);
};

export default GuaranteeSection;
