import React from 'react';
import Image from '../Components/Image';
import '../Style-SheetsKore/PromotionalSection.css';
import LernMoreButton from './LernMoreButton';
import PremotionImg from '../ImagesKore/PremotionImg.png';
const PromotionalSection = () => {
	return (
		<div className="PromotionalSectionContainer">
			<Image
				src={PremotionImg}
				alt={'Premotion Img'}
				className="ImgPromotional"
			/>

			<div className="PromotionalDataSection">
				<p className="h1Promotional">Oferta promocional especial ahora</p>
				<p className="promotionalh3SemiTitle">
					Ordene hoy y obtenga un 50% de descuento
				</p>
				<p className="P_Promotional">
					¡Este reloj inteligente ofrece un rendimiento excelente al precio
					perfecto! No te lo quitarás.
				</p>
				<p className="P_Promotional">
					¿Quieres este reloj inteligente ya súper asequible a un precio aún MÁS
					con descuento?
				</p>
				<LernMoreButton />
			</div>
		</div>
	);
};

export default PromotionalSection;
