import React, { useEffect, useState } from 'react';
import LinkButtonKore from './LinkButtonKore';
import '../Style-SheetsKore/StickyFooter.css';
const StickyFooter = () => {
	const [YLocation, SetYLocation] = useState(0);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			SetYLocation(window.pageYOffset);
		});
		return () => {
			window.removeEventListener('scroll');
		};
	}, []);

	return (
		<div
			className={
				YLocation > 500
					? 'StickyFooterContainer'
					: 'StickyFooterContainer hidden'
			}
		>
			<div className="outer">
				<div className="middle">
					<span className="spanTextStickyFooter">
						{'Ahora un 50% de descuento especial. Por tiempo limitado.'}
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					<span className="spaceAtStickyFooter" />
					<LinkButtonKore data={'Llévate ahora tu Kore 2.0'} />
				</div>
			</div>
		</div>
	);
};

export default StickyFooter;
