import React from 'react';
import img from '../ImagesKore/MainAdWatch.png';
import fiveStarimg from '../ImagesKore/fiveStars.png';

import Image from '../Components/Image';
import '../Style-SheetsKore/Description.css';
import LinkButtonKore from './LinkButtonKore';

const Description = (props) => {
	return (
		<div className="wrapperDivKore">
			<div>
				<Image src={img} alt={'Image'} className={'MainProductImg'} />
			</div>
			<div>
				<div>
					<h1>¡Datos de salud en tiempo real servidos de un vistazo!</h1>
				</div>

				<div>
					<Image
						src={fiveStarimg}
						alt={'Image'}
						className={'fiveStartsImage'}
					/>
					<span className="fiveStarsText">Según 676 reseñas</span>
				</div>
				<div>
					<p>
						La banda de fitness Kore 2.0 está equipada con métricas vitales
						importantes y sensores avanzados para obtener resultados confiables
						y rápidos, todo almacenado en un solo dispositivo.
					</p>
				</div>
				<div>
					<LinkButtonKore data="Ordene Kore 2.0 ahora" />
				</div>
			</div>
		</div>
	);
};

export default Description;
