import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const RedirectKore = (props) => {
	let params = useParams();

	// https://nerve.eshkol.io/go?uid=4a68af10fed19&area=keywordtest&campaign_id=campaign1&click_id=123456
	useEffect(() => {
		window.location.replace('https://nerve.eshkol.io/go' + params.data);
	}, []);
	return <div></div>;
};

export default RedirectKore;
