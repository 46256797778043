import React from 'react';
import img from '../images/BestSellerAmazon.jpeg';
import LinkButton from './LinkButton';
import Image from './Image';
import AdImage from '../images/zulaymain.png';
import '../Style-Sheets/Description.css';

const Description = (props) => {
	const price = '$15.99';

	return (
		<div className="wrapperDiv">
			<div>
				<Image src={AdImage} alt={'Image'} className={'MainProductImg'} />
			</div>
			<div>
				<div>
					<h2>Zulay Original Milk Frother</h2>
					<h2>Handheld Foam Maker</h2>
				</div>

				<div>
					<LinkButton />
				</div>
				<div>
					<a
						geid="1"
						href="https://www.amazon.com/Zulay-Milk-Frother-Handheld-Operated/dp/B074ZL35DH?th=1"
					>
						<Image src={img} alt={'MainImg'} className={'bestSellerImg'} />
					</a>
					<div>
						<a
							className="H2Reviews"
							geid="1"
							href="https://www.amazon.com/Zulay-Milk-Frother-Handheld-Operated/dp/B074ZL35DH?th=1"
						>
							60,000+ Reviews On Amazon!
						</a>
					</div>
				</div>
				<div>
					<div className="priceWrapper">
						<span>Price:</span>
						<span className="price">{price}</span>
						<ul className="listWrapper">
							<li>Make Rich, Creamy Froth In Seconds!</li>
							<li>Proven and Trusted Quality - Only Premium Materials.</li>
							<li>Zulay Guarantee - We Will Make It Right</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Description;
