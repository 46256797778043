import React from 'react';
import '../Style-SheetsKore/ActivityBox.css';
import Image from '../Components/Image';

const ActivityBox = (props) => {
	return (
		<div className={'containerActivityDiv'}>
			<Image
				className={'ActivityImg'}
				src={props.logo}
				alt={props.logo.toString()}
			/>
			<div className={'activityTextContainer'}>
				<h3>{props.title}</h3>
				<p>{props.text}</p>
			</div>
		</div>
	);
};

export default ActivityBox;
