import React from 'react';
import '../Style-SheetsKore/Footer.css';
import FooterLinks from './FooterLinks';

const Footer = () => {
	return (
		<div className="FooterContainer">
			<br />
			<span>Copyright ©2021Kore 2.0 | Todos los derechos reservados.</span>
			<br />
			<FooterLinks />
			<br />
			<span>
				Quality Performance Limited, 377 Valley Rd #1123, Clifton, NJ 07013
			</span>
			<br />
			<a className="linkAtFooter" href="tel:+8552194892">
				855 219 4892
			</a>
			<br />
			<br />
		</div>
	);
};

export default Footer;
