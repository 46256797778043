import React from 'react';

import '../Style-SheetsKore/Review.css';

import Image from '../Components/Image';
import V_sign from '../ImagesKore/V_sign.png';
const Review = (props) => {
	return (
		<div className={'reviewContainer'}>
			<h2>{props.title}</h2>
			<div>
				<Image
					src={props.src}
					alt={props.src.toString()}
					className={'starsImg'}
				/>
				<span>&nbsp; &nbsp;{props.semiData}</span>
			</div>
			<p>{props.data}</p>
			<div className="V_Sector">
				<Image src={V_sign} alt={'Sí, recomiendo este producto.'} />
				<span>Sí, recomiendo este producto.</span>
			</div>
		</div>
	);
};

export default Review;
