import '../Style-SheetsKore/HomePage.css';
import '../Style-Sheets/Main.css';

import React from 'react';
import faviconImg from '../ImagesKore/favicon-51c14345-89bb-43dc-a495-a8dfadc9759e.png';
import Header from './Header';
import Description from './Description';
import IconsBoxes from './IconsBoxes';
import ActivitiesBoxes from './ActivitiesBoxes';
import WatchFeatures from './WatchFeatures';
import Reviews from './Reviews';
import PromotionalSection from './PromotionalSection';
import GuaranteeSection from './GuaranteeSection';
import Questions from './Questions';
import Footer from './Footer';
import StickyFooter from './StickyFooter';

function getFaviconEl() {
	return document.getElementById('favicon');
}

const HomePageKore = () => {
	const handleTitleImg = () => {
		const favicon = getFaviconEl(); // Accessing favicon element
		favicon.href = faviconImg;
	};
	document.title = 'Kore 2.0';
	handleTitleImg();

	return (
		<div className="App">
			<Header />
			<div className="homePageDataContainer">
				<Description />
				<IconsBoxes />
				<ActivitiesBoxes />
				<WatchFeatures />
				<Reviews />
				<PromotionalSection />
				<br />
				<GuaranteeSection />
				<Questions />
				<Footer />
				<div className="GapAtBottom" />
			</div>
			<StickyFooter />
		</div>
	);
};

export default HomePageKore;
