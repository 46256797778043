import {} from '../Style-Sheets/ReviewC.css';
import React from 'react';
import Image from './Image';
import starsAmazon from '../images/5-stars-amazon.png';

const ReviewC = (props) => {
	return (
		<div className="mainDiv">
			<div className="nameContainer">
				<Image
					src={props.data.image}
					alt={props.data.name}
					className={'UserReviewImg'}
				/>

				<span className="name">{props.data.name}</span>
			</div>
			<Image src={starsAmazon} alt={'5star'} className={'Reviews5Stars'} />
			<span className="title"> {props.data.title}</span>
			<br />
			<span className="subTitle">{props.data.subTitle}</span>
			<br />
			<span className="Verified">Verified Purchase</span>
			<br />
			<span>{props.data.body}</span>
		</div>
	);
};

export default ReviewC;
