import React from 'react';
import Image from '../Components/Image';
import '../Style-SheetsKore/WatchFeatureItem.css';
const WatchFeatureItem = (props) => {
	return (
		<div className={'WatchFeatureItemContainer ' + props.className}>
			<Image
				className={'WatchFeatureItemImg'}
				src={props.logo}
				alt={props.logo.toString()}
			/>
			<div>
				<h1>{props.title}</h1>
				<p>{props.text}</p>
			</div>
		</div>
	);
};

export default WatchFeatureItem;
