import '../Style-Sheets/HomePage.css';
import '../Style-Sheets/Main.css';

import React from 'react';
import Description from './Description';
import HeadLogo from './HeadLogo';
import Slogan from './Slogan';
import VideoDisplay from './VideoDisplay';
import LinkButton from './LinkButton';
import ProductsImages from './ProductsImages';
import Reviews from './Reviews';

const HomePage = () => {
	return (
		<div className="App">
			<HeadLogo />
			<VideoDisplay />
			<Description />
			<Slogan />
			<ProductsImages />
			<br /> <br />
			<br />
			<LinkButton />
			<Reviews />
		</div>
	);
};

export default HomePage;
