import React from 'react';

import '../Style-SheetsKore/Header.css';

import headerImg from '../ImagesKore/kore2.0-logo-wh.png';
import Image from '../Components/Image';
const Header = () => {
	return (
		<div className="HeaderContainer">
			<div className="HeaderDataContainer">
				<span className="HeaderDataContainerSpans">
					<Image className="HeaderLogo" src={headerImg} alt={'headerImg'} />
				</span>
				<span className="HeaderData">
					<p>Ahora un 50% de descuento especial. Por tiempo limitado.</p>
				</span>
			</div>
		</div>
	);
};

export default Header;
