import React, { useEffect } from 'react';

const RedirectKore = (props) => {
	// https://nerve.eshkol.io/go?uid=4a68af10fed19&area=keywordtest&campaign_id=campaign1&click_id=123456
	useEffect(() => {
		window.location.replace('https://nerve.eshkol.io/go?uid=4a68af10fed19');
	}, []);
	return <div></div>;
};

export default RedirectKore;
