import React from 'react';
import '../Style-Sheets/Button.css';
const LinkButton = (props) => {
	return (
		<div>
			<a
				geid="1"
				href="https://www.amazon.com/Zulay-Milk-Frother-Handheld-Operated/dp/B074ZL35DH?th=1"
				className="designe"
			>
				Buy On Amazon
			</a>
			{/* <button
				onClick={() => {
					window.location.replace(
						'https://www.amazon.com/Zulay-Milk-Frother-Handheld-Operated/dp/B074ZL35DH?maas=maas_adg_api_8474408860701_static_9_18&ref_=aa_maas&aa_campaignid=Eshkol1&aa_adgroupid=unt&aa_creativeid=unt'
					);
					// window.gtag_report_conversion(
					// 	'https://www.amazon.com/Zulay-Milk-Frother-Handheld-Operated/dp/B074ZL35DH?th=1'
					// );
				}}
				// geid="1"
				// href="https://www.amazon.com/Zulay-Milk-Frother-Handheld-Operated/dp/B074ZL35DH?th=1"
				className="designe"
			>
				Buy On Amazon
			</button> */}
		</div>
	);
};

export default LinkButton;
