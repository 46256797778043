import React from 'react';
import WatchFeatureItem from './WatchFeatureItem';

import firstWatchFeature from '../ImagesKore/WatchFeaturesImages/firstWatchFeature.png';
import secondWatchFeature from '../ImagesKore/WatchFeaturesImages/secondWatchFeature.png';
import thirdWatchFeature from '../ImagesKore/WatchFeaturesImages/thirdWatchFeature.png';

const WatchFeatures = () => {
	return (
		<div>
			<WatchFeatureItem
				className={'WatchFeatureItemContainerLeft'}
				logo={firstWatchFeature}
				title="Mantenerse activo "
				text="¡Kore 2.0 está diseñado para el uso diario! Es resistente al sudor y a las salpicaduras, por lo que puede contar los pasos, el kilometraje y las calorías, ya sea que esté corriendo, levantando pesas o haciendo cualquier otra actividad. También es muy cómodo; Úselo mientras hace ejercicio y realice un seguimiento del progreso con la útil aplicación, o úselo por la noche para realizar un seguimiento de sus patrones de sueño."
			/>
			<WatchFeatureItem
				className={'WatchFeatureItemContainerRight'}
				logo={secondWatchFeature}
				title="Mantente informado"
				text="Comprométase más que nunca con su salud y estado físico. Los sensores duales brindan lecturas precisas de los signos vitales de su cuerpo, incluida la frecuencia cardíaca, el oxígeno en la sangre y la temperatura de la piel, ¡todo con solo un vistazo a su muñeca!"
			/>
			<WatchFeatureItem
				className={'WatchFeatureItemContainerLeft'}
				logo={thirdWatchFeature}
				title="Mantente conectado"
				text="Kore 2.0 incluye navegación por pantalla táctil y una plataforma de carga fácil con más de una semana de duración de la batería. Todas las aplicaciones, notificaciones y alertas al alcance de su mano gracias a la conectividad rápida a cualquier dispositivo Apple o Android."
			/>
		</div>
	);
};

export default WatchFeatures;
