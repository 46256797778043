import React from 'react';
import '../Style-SheetsKore/Button.css';
import arrow_forward_ios from '../ImagesKore/arrow_forward_ios.png';
import Image from '../Components/Image';
const LinkButtonKore = (props) => {
	//Ordene Kore 2.0 ahora
	return (
		<>
			<button
				geid="1"
				href="https://www.buykorewatch.com/latam/order-pre.html?icta=order-pre.html&iep=true&loader=1&fomo=1&Affid=5585&s1=&s2=&s3=&s4=4374&s5=dc2b5f1411a64b7ebbe2f4449fd5c5ab&domain1=www.finegizmos.com&network_id=69&ctr_tracking__site_id=61484c72770ec816c071e17d&ctr_tracking__page_id=6147cf00770ec816c071e17f&ctr_tracking__conversion_url=https%3A%2F%2Fwww.buykorewatch.com%2Flatam%2Findex.html&ctr_tracking__click_id=09313744-88ab-4ac1-8d9d-8baac5bdd85d&ctr_tracking__original_click_id=09313744-88ab-4ac1-8d9d-8baac5bdd85d"
				className="designeKore"
			>
				<div className="buttonDataContainer">
					<div>
						<Image src={arrow_forward_ios} alt="arrow" />
					</div>
					<span className="spanDataOfButton"> {props.data} </span>
				</div>
			</button>
		</>
	);
};

export default LinkButtonKore;
