import React from 'react';
import '../Style-Sheets/slogan.css';
const Slogan = () => {
	const sloganData = '"Indulge Yourself"';
	return (
		<div className="sloganWrapper">
			<br />
			<h1>{sloganData}</h1>
			<br /> <br />
		</div>
	);
};

export default Slogan;
