import React from 'react';
import '../Style-SheetsKore/FooterLinks.css';
const FooterLinks = () => {
	const SPREAD = ' | ';

	return (
		<div>
			<a
				className="linkAtFooter"
				href="https://www.intoposts.com/contact-us/?utm_term=undefined&utm_campaign=undefined&utm_source=undefined&utm_medium=undefined"
			>
				Contactar
			</a>
			{SPREAD}
			<a
				className="linkAtFooter"
				href="https://www.intoposts.com/terms-and-conditions/?utm_term=undefined&utm_campaign=undefined&utm_source=undefined&utm_medium=undefined"
			>
				Términos y Condiciones
			</a>
			{SPREAD}
			<a
				className="linkAtFooter"
				href="https://www.intoposts.com/privacy-policy-3/?utm_term=undefined&utm_campaign=undefined&utm_source=undefined&utm_medium=undefined"
			>
				Política de Privacidad
			</a>
			{SPREAD}
			<a
				className="linkAtFooter"
				href="https://www.intoposts.com/about-us/?utm_term=undefined&utm_campaign=undefined&utm_source=undefined&utm_medium=undefined"
			>
				Sobre nosotros
			</a>
		</div>
	);
};

export default FooterLinks;
