import React from 'react';
import DivOfImages from './DivOfImages';
import Image from './Image';
import MyProductOnADesk from '../images/image4.png';
import MyProductDescription from '../images/8047b5f2b0df40c68f48ead2a80dec50.png';
import MyProductIntroduction from '../images/zulayimage2.png';

const ProductsImages = (props) => {
	return (
		<DivOfImages>
			<Image
				src={MyProductOnADesk}
				alt={'My product on a desk'}
				className={'gallery'}
			/>
			<Image
				src={MyProductDescription}
				alt={'My product description'}
				className={'gallery'}
			/>
			<Image
				src={MyProductIntroduction}
				alt={'My product frother Steamer Cup'}
				className={'gallery'}
			/>
		</DivOfImages>
	);
};

export default ProductsImages;
