import React from 'react';
import '../Style-SheetsKore/Questions.css';
import QuestionItem from './QuestionItem';

const Questions = () => {
	const questionsList = [
		{
			question:
				'¿Kore 2.0 es un reloj inteligente o un rastreador de ejercicios?',
			answer:
				'¡Ambos! Kore 2.0 combina el estilo y la comodidad de un reloj inteligente con las características biométricas de una pulsera de fitness.',
		},
		{
			question: '¿Cómo controlo Kore 2.0?',
			answer:
				'Sí, Kore 2.0 puede almacenar datos por sí mismo durante 7 días como máximo. Se sincronizará automáticamente con tu teléfono la próxima vez que esté dentro del alcance Bluetooth.',
		},
		{
			question: '¿Kore 2.0 es resistente al agua?',
			answer:
				'Kore 2.0 es resistente al sudor y a las salpicaduras, por lo que es seguro usarlo cerca del agua, pero no debes nadar con él.',
		},
		{
			question: '¿Puedo usar Kore 2.0 si no tengo mi teléfono encima?',
			answer:
				'Sí, Kore 2.0 puede almacenar datos hasta por 7 días por sí solo. Se sincronizará automáticamente con su teléfono la próxima vez que esté dentro del alcance de Bluetooth.',
		},
		{
			question: '¿Funcionará Kore 2.0 si tengo tatuajes?',
			answer:
				'Sí, el chip sensor mejorado de Kore 2.0 proporciona lecturas precisas para usuarios con tatuajes.',
		},
		{
			question: '¿Hay diferentes opciones de color para la pulsera Kore 2.0?',
			answer:
				'Sí, las pulseras deportivas Kore 2.0 están disponibles en una variedad de colores y se pueden comprar al momento de pagar.',
		},
	];

	return (
		<div className="QuestionsContainer">
			<p className="QuestionsTitle">PREGUNTAS FRECUENTES</p>
			{questionsList.map((item, index) => (
				<QuestionItem
					key={index}
					question={item.question}
					answer={item.answer}
				/>
			))}
			<br />
			<br />
		</div>
	);
};

export default Questions;
