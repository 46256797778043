import React from 'react';
import Alvin from '../images/reviewers/Alvin.png';
import George from '../images/reviewers/George.png';
import Marisa from '../images/reviewers/Marisa.png';

import ReviewC from './ReviewC';
const Reviews = () => {
	const reviewsData = [
		{
			name: 'Alvin’s Attic',
			image: Alvin,
			title: 'Make great Cappuccino and Lattes!!',
			subTitle: 'Reviewed in the United States on October 2, 2019',
			body: 'The milk frother Set is a jewel! I bought this after getting rid of my Ninja Coffee Machine which had a frother arm. The Zulay Kitchen frother has great power. I love that the set included the stainless steel frothing pitcher with measurements on the inside. I haven’t used the stencils yet, but they are super cute! I would definitely buy again!! Actually going to buy some for Christmas gifts.',
		},
		{
			name: 'George',
			image: George,
			title: 'Not sure why I waited!',
			subTitle: 'Reviewed in the United States on September 2, 2021',
			body: 'I have no idea why I waited so long to purchase this! The box comes with a welcome/about message and a QR code to watch videos on how to use it and a coupon for additional purchases! I love this! I use it everyday!',
		},
		{
			name: 'Marisa S.',
			image: Marisa,
			title: 'Awesome little tool',
			subTitle: 'Reviewed in the United States on April 3, 2021',
			body: 'This product is amazing! I didn’t think such a small whisk could make such a thick froth SO quickly! It is AMAZING! I am in LOVE with this little device...will def buy from this company again! Love the family story behind it!',
		},
	];
	const showResults = reviewsData.map((item, index) => (
		<ReviewC key={index} data={item} />
	));
	return <div>{showResults}</div>;
};

export default Reviews;
