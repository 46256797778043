import React from 'react';
import '../Style-Sheets/VideoDisplay.css';
const VideoDisplay = (props) => {
	return (
		<div className="iframeWrapper">
			<iframe
				className="iframe"
				src="https://player.vimeo.com/video/647302603?h=487efb6338"
				title="vimeo video"
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				allowFullScreen
			></iframe>
		</div>
	);
};

export default VideoDisplay;
